import { Component, Input, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';
import { Pagination, Projets } from 'src/app/app.models';
import { AppService } from 'src/app/app.service';
import { AppSettings, Settings } from 'src/app/app.settings';
import { CompareOverviewComponent } from 'src/app/shared/compare-overview/compare-overview.component';

@Component({
  selector: 'app-projet-item',
  templateUrl: './projet-item.component.html',
  styleUrls: ['./projet-item.component.scss']
})
export class ProjetItemComponent implements OnInit {
  @Input() property: Projets;
  @Input() viewType: string = "grid";
  @Input() viewColChanged: boolean = false; 
  @Input() fullWidthPage: boolean = true;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  
  public properties: Projets[];
  public column:number = 4;
  public count: number = 8;
  public message:string;
  public sort: string;
  public searchFields: any;
  public settings: Settings;
  public config: SwiperConfigInterface = {};
  public paginations:Pagination = new Pagination(1, 8, null, 2, 0, 0);
  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  constructor(
    public appSettings:AppSettings,
    public appService:AppService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    console.log(this.getProperties());
  }

  public getProperties(){
    this.appService.getProjet().subscribe(data => {     
      if(this.properties && this.properties.length > 0){  
        this.settings.loadMore.page++;
        this.paginations.page = this.settings.loadMore.page;
      }
      let result = this.filterData(data);       
      if(result.data.length == 0){
        this.properties.length = 0;
        this.paginations = new Pagination(1, this.count, null, 2, 0, 0);  
        this.message = 'No Results Found';
        return false;
      }   
      if(this.properties && this.properties.length > 0){   
        this.properties = this.properties.concat(result.data);          
      }
      else{
        this.properties = result.data;  
      } 
      this.paginations = result.pagination;
      this.message = null;

      if(this.properties.length == this.paginations.total){
        this.settings.loadMore.complete = true;
        this.settings.loadMore.result = this.properties.length;
      }
      else{
        this.settings.loadMore.complete = false;
      }
      console.log(data);
      return data
    })
  }

  public getProjets(){
    this.appService.getProjet().subscribe(data => { 
      let result = this.filterData(data);
      if(result.data.length == 0){
        this.properties.length = 0;
        this.paginations = new Pagination(1, this.count, null, 2, 0, 0);  
        this.message = 'No Results Found';
        return false;
      }
      this.properties = result.data; 
      this.paginations = result.pagination;
      this.message = null;
    })
  }

  public filterData(data){
    return this.appService.filterData(data, this.searchFields, this.sort, this.paginations.page, this.paginations.perPage);
  }

  ngAfterViewInit(){
    this.initCarousel();
  } 
 
  ngOnChanges(changes: {[propKey: string]: SimpleChange}){  
    if(changes.viewColChanged){
      this.getColumnCount(changes.viewColChanged.currentValue);
      if(!changes.viewColChanged.isFirstChange()){
        if(this.property.gallery.length > 1){     
           this.directiveRef.update();  
        } 
      }
    } 
  }

  public getColumnCount(value){
    if(value == 25){
      this.column = 4;
    }
    else if(value == 33.3){
      this.column = 3;
    }
    else if(value == 50){
      this.column = 2
    }
    else{
      this.column = 1;
    }
  }


public getProjetBgColor(status){
    switch (status) {
      case 'Terminé':
        return '#558B2F'; 
      case 'En cours':
        return '#1E88E5'; 
      case 'En attente':
        return '#FFA000';  
      case 'Arrêter':
        return '#F44336';   
      case 'For Rent':
        return '#1E88E5'; 
      case 'Open House':
        return '#009688';
      case 'KPI':
        return '#dd6d07';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default: 
        return '#01579B';
    }
  }

  public getStatusBgColor(status){
    switch (status) {
      case 'For Sale':
        return '#558B2F';  
      case 'For Rent':
        return '#1E88E5'; 
      case 'Open House':
        return '#009688';
      case 'No Fees':
        return '#FFA000';
      case 'Hot Offer':
        return '#F44336';
      case 'Sold':
        return '#000';
      default: 
        return '#01579B';
    }
  }

  public initCarousel(){
    this.config = {
      slidesPerView: 1,
      spaceBetween: 0,         
      keyboard: false,
      navigation: true,
      pagination: this.pagination,
      grabCursor: true,        
      loop: true,
      preloadImages: false,
      lazy: true,  
      nested: true,
      speed: 500,
      effect: "slide"
    }
  }
  

  public addToCompare(){
    this.appService.addToCompare(this.property, CompareOverviewComponent, (this.settings.rtl) ? 'rtl':'ltr'); 
  }

  public onCompare(){
    return this.appService.Data.compareList.filter(item=>item.id == this.property.id)[0];
  }

  public addToFavorites(){
    this.appService.addToFavorites(this.property, (this.settings.rtl) ? 'rtl':'ltr');
  }

  public onFavorites(){
    return this.appService.Data.favorites.filter(item=>item.id == this.property.id)[0];
  }


}