import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avis-espert',
  templateUrl: './avis-espert.component.html',
  styleUrls: ['./avis-espert.component.scss']
})
export class AvisEspertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
