export class Property {
    constructor(
        public id: number,
        public title: string,
        public periode: string,
        public montantD: string,
        public montantR: string,
        public pourcentage: string,
        public objectif: string,
        public longitude: string,
        public latitude: string,
        public superficie: string, 
        public desc: string,
        public numero: any,
        public region: any,
        public departement: any,
        public itineraireP: string,
        public itineraireE: string,
        public bailleur: any,
        public demarage: any,
        public fin: any,
        public propertyType: string,
        public status: string, 
        public propertyStatus: string[], 
        public city: string,
        public zipCode: string[],
        public neighborhood: string[],
        public street: string[],
        public location: Location,
        public formattedAddress: string,
        public pays: string,
        public cloture: string,
        public invest_minim: string,
        public invest_minim_interet: string,
        public invest_collect: string,
        public ville: string,
        public commune: string,
        public quartier: string,
        public features: string[],
        public featured: boolean,
        public priceDollar: Price,
        public priceEuro: Price,
        public bedrooms: number,
        public bathrooms: number,
        public garages: number,
        public area: Area,
        public yearBuilt: number,
        public ratingsCount: number,
        public ratingsValue: number,
        public additionalFeatures: AdditionalFeature[],
        public gallery: Gallery[],
        public plans: Plan[],
        public plans3D: Plan[],
        public videos: Video[],
        public published: string,
        public lastUpdate: string,
        public views: number
    ){ }
}

export class Projets {
    constructor(
        public id: number,
        public title: string,
        public periode: string,
        public montantD: string,
        public montantR: string,
        public pourcentage: string,
        public numero: any,
        public region: any,
        public departement: any,
        public itineraireP: string,
        public itineraireE: string,
        public bailleur: any,
        public demarage: any,
        public fin: any,
        public objectif: string,
        public longitude: string,
        public latitude: string,
        public superficie: string,
        public desc: string,
        public propertyType: string,
        public status: string, 
        public propertyStatus: string[], 
        public city: string,
        public cloture: string,
        public invest_minim: string,
        public invest_minim_interet: string,
        public invest_collect: string,
        public zipCode: string[],
        public neighborhood: string[],
        public street: string[],
        public location: Location,
        public formattedAddress: string,
        public pays: string,
        public ville: string,
        public commune: string,
        public quartier: string,
        public features: string[],
        public featured: boolean,
        public priceDollar: Price,
        public priceEuro: Price,
        public bedrooms: number,
        public bathrooms: number,
        public garages: number,
        public area: Area,
        public yearBuilt: number,
        public ratingsCount: number,
        public ratingsValue: number,
        public additionalFeatures: AdditionalFeature[],
        public gallery: Gallery[],
        public plans: Plan[],
        public plans3D: Plan[],
        public videos: Video[],
        public published: string,
        public lastUpdate: string,
        public views: number
    ){ }
}

export class Conversely {
    constructor(
        public id: number,
        public projet: Projets, 
        public periode: string,
        public montantD: string,
        public montantR: string,
        public pourcentage: string,
        public objectif: string, 
        public longitude: string,
        public latitude: string,
        public superficie: string,
        public desc: string,
        public montant: number,
        public propertyType: string,
        public status: string, 
        public propertyStatus: string[], 
        public city: string,
        public zipCode: string[],
        public neighborhood: string[],
        public street: string[],
        public location: Location,
        public formattedAddress: string,
        public pays: string,
        public ville: string,
        public commune: string,
        public quartier: string,
        public features: string[],
        public featured: boolean,
        public priceDollar: Price,
        public priceEuro: Price,
        public bedrooms: number,
        public bathrooms: number,
        public garages: number,
        public area: Area,
        public yearBuilt: number,
        public ratingsCount: number,
        public ratingsValue: number,
        public additionalFeatures: AdditionalFeature[],
        public gallery: Gallery[],
        public plans: Plan[],
        public plans3D: Plan[],
        public videos: Video[],
        public published: string,
        public lastUpdate: string,
        public views: number
    ){ }
} 


export class Area {
    constructor(
        public id: number, 
        public value: number,
        public unit: string
    ){ }
}

export class AdditionalFeature {
    constructor(public id: number, 
                public name: string,
                public value: string){ }
}

export class Location {
    constructor(public propertyId: number,
                public lat: number,
                public lng: number){ }
}

export class Price {
    public sale: number;
    public rent: number;
}


export class Gallery {
    constructor(public id: number, 
                public small: string,
                public medium: string,
                public big: string){ }
}

export class Plan {
    constructor(public id: number, 
                public name: string,
                public desc: string,
                public area: Area,
                public rooms: number,
                public baths: number,
                public image: string){ }
}

export class Video {
    constructor(public id: number, 
                public name: string,
                public link: string){ }
}

export class Pagination {
    constructor(public page: number,
                public perPage: number,
                public prePage: number,
                public nextPage: number,
                public total: number,
                public totalPages: number){ }
}

