import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AgmCoreModule } from '@agm/core';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ProjetItemComponent } from './projet/projet-item/projet-item.component';
import { ProjetListComponent } from './projet/projet-list/projet-list.component';
import { MapComponent } from './inverstissement/map/map.component';
import { FondComponent } from './fond/fond.component';
import { InverstieComponent } from './inverstieeur/inverstie/inverstie.component';
import { AvisEspertComponent } from './inverstieeur/avis-espert/avis-espert.component';
import { FaconInverstirComponent } from './inverstieeur/facon-inverstir/facon-inverstir.component';
import { HeaderMapComponent } from '../shared/header-map/header-map.component';
import { NotFoundComponent } from '../pages/not-found/not-found.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export const routes = [
  { path: 'projet', component: ProjetListComponent  },
  { path: 'map', component: HeaderMapComponent  },
  { path: 'fond', component: FondComponent  },
  { path: 'inverstir', component: InverstieComponent  },
  { path: 'avis', component: NotFoundComponent  },
  { path: 'façon', component: FaconInverstirComponent  },
  { path: '**', component: NotFoundComponent }

];

@NgModule({
  declarations: [
    ProjetListComponent,
    MapComponent,
    FondComponent,
    InverstieComponent,
    AvisEspertComponent,
    FaconInverstirComponent,
    ProjetItemComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule,
    PerfectScrollbarModule,
    RouterModule.forChild(routes),
  ],
  exports: [
    ProjetItemComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class InverstissementModule { }
