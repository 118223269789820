import { Component, OnInit } from '@angular/core';
import { Settings, AppSettings } from '../../../app.settings';
import { Router } from '@angular/router';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
  dividers:boolean = true;
  iconSize:string = 'sm';
  iconColor:string = '';
  public currencies = ['XOF', 'USD', 'EUR'];
  public consoles = ['MANAGEMENT ZENAPI'];
  public currency:any; 
  public console:any; 
  public settings: Settings;
  constructor(public appSettings:AppSettings,
    public router: Router,
  ) {
    this.settings = this.appSettings.settings;
    
  }

  ngOnInit() {
    this.currency = this.settings.currency;
    this.console = "CONSOLE"
  }
  
  public changeCurrency(currency){
    this.currency = currency;
    this.settings.currency = currency;
  } 

  onCLick(type){
    if(type == 'MANAGEMENT ZENAPI'){
      
      window.open('https://app.zenapi.immo/','_blank')
    }else{
      this.router.navigate(['/login'])
    }
  }
}
