import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-facon-inverstir',
  templateUrl: './facon-inverstir.component.html',
  styleUrls: ['./facon-inverstir.component.scss']
})
export class FaconInverstirComponent implements OnInit {

  public contactForm: FormGroup;
  public lat: number = 40.678178;
  public lng: number = -73.944158;
  public zoom: number = 12; 
  public text = "Investir dans l'immobilier commercial peut être un élément important de la constitution d'un portefeuille diversifié. Mais il n'y a pas de façon unique d'investir. Que vous soyez un expert chevronné de l'immobilier ou complètement nouveau dans l'investissement immobilier commercial, nous vous aidons à trouver facilement l'approche qui vous convient.";
  constructor(public formBuilder: FormBuilder) { }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.compose([Validators.required, emailValidator])],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  public onContactFormSubmit(values:Object):void {
    if (this.contactForm.valid) {
      console.log(values);
    }
  }

}
