import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { emailValidator } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @Input('propertyId') propertyId;
  public commentForm: FormGroup;
  public reviews = [
    { 
      author: 'Kouassi ghislan', 
      avatar: 'assets/images/avatars/av-1.png', 
      tooltip: 'Dissatisfied', 
      icon: 'sentiment_dissatisfied',
      date: '13 January, 2018 at 7:09',
      text: "Ce marché comprendra notamment les prestations suivantes : - élaboration de stratégie de communication : plans de communication, plate-forme de discours, etc ; - préconisation de nouveaux supports d'accompagnement et marketing-service adaptés aux nouveaux usages des professionnels de santé : on / off line ; en face à face / dématérialisés, etc ; - conception et réalisation des supports de la relation-client."
    },
    { 
      author: 'Amon roland', 
      avatar: 'assets/images/avatars/av-2.png', 
      tooltip: 'Very Satisfied', 
      icon: 'sentiment_very_satisfied',
      date: '04 February, 2018 at 10:22',
      text: 'Excellent investissement qui est tres promoteur'
    },
    { 
      author: 'Quouddouss', 
      avatar: 'assets/images/avatars/av-3.jpg', 
      tooltip: 'Neutral', 
      icon: 'sentiment_neutral',
      date: '14 February, 2018 at 11:10',
      text: "Ce marché comprendra notamment les prestations suivantes : - élaboration de stratégie de communication : plans de communication, plate-forme de discours, etc ; - préconisation de nouveaux supports d'accompagnement et marketing-service adaptés aux nouveaux usages des professionnels de santé : on / off line ; en face à face / dématérialisés, etc ; - conception et réalisation des supports de la relation-client."
    }
  ];
  public ratings = [
    { title: 'Tres Insatisfait', icon: 'sentiment_very_dissatisfied', percentage: 20, selected: false },
    { title: 'Insatisfait', icon: 'sentiment_dissatisfied', percentage: 40, selected: false },
    { title: 'Neutre', icon: 'sentiment_neutral', percentage: 60, selected: false },
    { title: 'Satisfait', icon: 'sentiment_satisfied', percentage: 80, selected: false },
    { title: 'Tres Satisfait', icon: 'sentiment_very_satisfied', percentage: 100, selected: false }
  ];
  constructor(public fb: FormBuilder) { }

  ngOnInit() {
    this.commentForm = this.fb.group({ 
      review: [null, Validators.required],            
      name: [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      rate: null,
      propertyId: this.propertyId
    }); 
  }

  public onCommentFormSubmit(values:any){
    if (this.commentForm.valid) { 
      console.log(values);
      if(values.rate){
        //property.ratingsCount++,
        //property.ratingsValue = property.ratingsValue + values.rate,
      }     
    } 
  }

  public rate(rating:any){
    this.ratings.filter(r => r.selected = false);
    this.ratings.filter(r => r.percentage == rating.percentage)[0].selected = true;
    this.commentForm.controls.rate.setValue(rating.percentage);
  }

}